.App {
  text-align: center;
}
video {
  object-fit: contain !important;
}

.videoPlayer {
  background-size: cover !important;
}
